.table-caption-label {
    line-height: 1;
    border: 0px solid black;
  }

  .sliding-text {
    margin-top: 0px;
    line-height: 1;
    border: 1px solid black;
    z-index: 100;
    position: absolute;
    display: none;
  }

  .fixed-table {
    line-height: 1;
    border: 0px solid black;
    z-index: 50;
    position: relative;
  }

  .table{
    border: 0px solid #eee;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 20px;
  }
  .table th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
  }
  .table td{
    padding: 5px 10px;
    border: 1px solid #eee;
    text-align: left;
  }
  .table tbody tr:nth-child(odd){
    background: #fff;
  }
  .table tbody tr:nth-child(even){
    background: #F7F7F7;
  }

  .table caption{
    line-height: 3;
    background: #d9e0d4;
    font-weight: bold;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
  }
  .button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }
  
  .button:hover {background-color: #3e8e41}

  .button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }