.tableRes{
    border: 0px solid #eee;
    margin-bottom: 20px;
    margin-left: 75px;
  }
  .tableRes td{
    font-weight: bold;
    border: none;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
  }
  .tableRes tbody tr:nth-child(odd){
    background: #fff;
  }
  .tableRes tbody tr:nth-child(even){
    background: #F7F7F7;
  }
