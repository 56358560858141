.table-caption-label {
    line-height: 1;
    border: 0px solid black;
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    width: calc(100% - 50px);
    float: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
  }


  