.table-caption-label {
    line-height: 1;
    border: 0px solid black;
  }

  .input-th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 0px solid #dddddd;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    text-align: center;
    float: left;
    width: calc(100% - 50px);
  }